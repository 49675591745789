<template>
	<div class="full-height">
		<div class="header filter">
			<div class="top top_mafi"><!--trans-->
				<div class="util shadow ">
					<h2 class="color-white">{{  $language.live.live_request_title }}</h2>
				</div>
				<div class="save">
					<button
						@click="goBack"
						class="btn_save"
					><v-icon class="color-white">mdi-close</v-icon></button>
				</div>
			</div>
		</div>
		<div class="section_wrap pt-50 flex-column justify-space-between full-height">
			<!-- 커버등록 -->
			<div class="cartel_cover">
				<label class="box pa-5-10 radius-10 position-absolute size-px-12 bg-white-half" style="right: 10px; top: 10px; z-index: 9">
					<v-icon small class="">mdi-camera</v-icon>
					썸네일 사진 등록
					<InputFileNew class="fileform_hidden" accept="image/*" @change="setFile($event, 'mobile')" ref="file" file_upload_code_info="CM00700021" />
				</label>
				<div
					v-if="item_live.cartl_chttg_img_url"
					class="cover_background"
				>
					<img
						:src="item_live.cartl_chttg_img_url"
						style="width: 100%"
					/>
				</div>

				<div
					v-else
					class="text-center size-px-12 text-shadow flex-column justify-center full-height color-white"
				>썸네일을 등록하세요 <br/> <span class="color-gray">* 가로 750px 세로 500px 이상</span></div>

			</div>

			<div class="container">
				<div class="subindex_mainbox">

					<!-- 카르텔이름 -->
					<div class="input_area">
						<div class="bright_input">
							<label for="cartelName">{{  $language.live.live_name }}</label>
							<div class="input_utill">
								<input
									v-model="item_live.cartl_chttg_room_name"
									type="text" id="cartelName" :placeholder="$language.live.live_name_required" maxlength="40"
								>
								<div class="input_utill_area">
									<div class="btn_del">
										<!-- TODO : 텍스트 입력시에만 .active 로 닫기 버튼 활성화 -->
										<button
											v-if="item_live.cartl_chttg_room_name"
											@click="item_live.cartl_chttg_room_name = ''"
											class="active"
										><span class="hide">{{  $language.live.live_name_required }}</span></button>
									</div>
									<!-- TODO : 텍스트 수 카운트 필요 -->
									<span class="input_text_count">{{ item_live.cartl_chttg_room_name.length }}/40</span>
								</div>
							</div>
						</div>
					</div>
					<!-- //카르텔이름 -->
				</div>
			</div>

			<div
				class="mt-auto btn_area pa-20"
			>
				<button
					@click="postCartelLive"
					class="btn_l btn_fill_red"
					:disabled="is_live_request"
				>{{  $language.live.live_request }}</button>
			</div>
		</div>
	</div>
</template>
<script>
import InputFileNew from "@/components/InputFileNew";
export default {
	name: 'CartelLiveRequest'
	, components: {InputFileNew}
	, props: ['callBack', 'user']
	, data: function(){
		return {
			program: {
				name: 'CartelLiveRequest'
				, title: this.$language.cartel.cartel_live_request
				, type: 'mypage'
				, not_footer: true
				, not_header: true
				, is_next: true
				, callBack: {
					name: 'cartel'
					, step: 1
				}
			}
			, item_cartel: {
				step: 1
				, cartl_chttg_room_name: ''
				, cartl_desctn: ''
				, entry_approval_fg: true
				, concern_sphere_code: ''
				, user_id: this.user.id
				, member_social_url_list: [
					{ member_social_url: ''}
				]
				, cartl_number: ''
			}
			, cartel_img: ''
			, sns_count: 1
			, item_live: {
				cartl_chttg_room_name: ''
				, stremg_fg: true
				, cartl_number: this.$route.params.idx
				, cartl_chttg_img_url: ''
				, cartl_chttg_room_div_code: 'CA02900001'
			}
		}
	}
	, computed: {
		is_live_request: function(){
			let t = true
			if(this.item_live.cartl_chttg_room_name){
				t = false
			}
			return t
		}
	}
	, methods: {

		setFile: function(e){
			this.$set(this.item_live, 'cartl_chttg_img_url', e)
		}
		, postFile: async function(){
			return true
		}
		, postCartelLive: async  function(){
			console.log('postCartelLive', this.item_live)

			if(await this.postFile()){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_cartel_live_request
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, cartl_chttg_room_name: this.item_live.cartl_chttg_room_name
							, cartl_chttg_room_desctn: this.item_live.cartl_chttg_room_desctn
							, cartl_chttg_room_div_code: this.item_live.cartl_chttg_room_div_code
							, stremg_fg: this.item_live.stremg_fg ? 'Y' : 'N'
							, cartl_chttg_img_url: this.item_live.cartl_chttg_img_url
							, invitation_member_list: []
						}
						, name: 'postCartelLive'
						, type: true
					})

					if(result.success){
						this.$bus.$emit('toLive', { name: 'CartelLiveDetail', params: { idx: this.item_live.cartl_number, live_idx: result.data.cartl_chttg_room_number}})
					}else{
						throw result.message
					}

				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
		}

		,goBack: function(){
			this.$emit('goBack')
		}
		, snsCount: function(){
			if(this.sns_count < 5){
				this.item_cartel.member_social_url_list.push({
					member_social_url: ''
				})
				this.sns_count++
			}
		}
		, removeSns: function(i){
			this.item_cartel.member_social_url_list.splice(i, 1)
			this.sns_count--
		}
	}
	,created() {
		this.$emit('onLoad', this.program)
		document.body.scrollTop = 0
	}
}
</script>

<style>
.box-profile-outline {
	position: absolute; left: calc(50% - 45px); bottom: -45px; z-index: 9;
	display: flex; flex-direction: column; align-items: center; justify-content: center;
	background-color: #2C2C2C; width: 95px; height: 95px;
	border-radius: 100%;
	overflow: hidden;
}

.box-profile-inline {
	position: absolute; left: 0; top: 0; z-index: 9;
	display: flex; flex-direction: column; align-items: center; justify-content: center;
	width: 95px; height: 95px;
	font-size: 12px;
	border-radius: 100%;
	color: var(--white);
}
.box-profile-outline img {
	object-fit: cover;
}
</style>